<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CALLER'S INCOME</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            class="mx-3"
            :items="callers_items"
            item-value="id"
            item-text="name"
            label="Caller's Name"
            required
            @change="selected_callers"
            v-model="callers_id"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="callers_data"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="subtitle-1">Total Amount:  {{formatPrice(total)}}</span>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.last_name+', '+item.first_name+' '+item.first_name }}</td>
            <td class="text-center">
              {{ item.mode_of_service }}
            </td>
            <td class="text-center">
              {{ item.date_of_death }}
            </td>
            <td class="text-center">
              {{ item.date_of_internment }}
            </td>
            <td class="text-center">
              {{ item.casket_type}}
            </td>
            <td class="text-center">
              {{ formatPrice(item.casket_price)}}
            </td>
            <td class="text-center">
              {{ formatPrice(item.callers)}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'

  export default {
    created() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'NAME', value: 'name', sortable: false},
          {text: 'MODE OF SERVICE', value: 'mode_of_service', sortable: false},
          {text: 'DATE OF DEATH', value: 'date_of_death', sortable: false},
          {text: 'DATE OF INTERNMENT', value: 'date_of_internment', sortable: false},
          {text: 'CASKET TYPE', value: 'casket_type', sortable: false},
          {text: 'CASKET PRICE', value: 'casket_type', sortable: false},
          {text: 'CALLER', value: 'callers', sortable: false},
        ],

        data: [],
        callers_data: [],

        month_of_items: [],
        month_of: '',
        callers_items: [],
        callers_id: 0,

        total: 0,
      }
    },
    computed: {
      ...mapGetters('users', ['positions']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'user_id', 'branch_contact_no', 'branch_address']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deceased_client', ['get_callers_income_report']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      monthly_remittances() {
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('month_of', this.month_of_items[index].month_of);
          this.get_callers_income_report(data)
            .then(response => {
              this.callers_id = 0
              this.callers_data = []
              this.callers_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_callers() {
        var index = this.callers_items.map(function (x) {
          return x.id;
        }).indexOf(this.callers_id)
        if (index != -1) {
          this.callers_data = this.callers_items[index].data
          this.total = this.callers_items[index].total
        }
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        var indexCollector = this.callers_items.map(function (x) {
          return x.id;
        }).indexOf(this.callers_id)

        var month = this.month_of_items[indexMonth].month_of
        var collector = this.callers_items[indexCollector].name
        var dep_id = this.data.id
        var dep_amount = this.data.amount
        var total_net = this.data.net
        var total_diff = this.data.balance
        var branch = this.data.branch
        payments_array.push(
          [
            {text: 'OR Date', alignment: 'center', style: 'label'},
            {text: 'OR No', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Remarks', alignment: 'center', style: 'label'}
          ]
        )
        if (this.callers_remittances_data.length > 0) {
          this.callers_remittances_data.forEach(function (item, index, payment) {
            var or_no = item.or_no
            var splitedOr = item.or_no.split('-')
            if (splitedOr.length > 0) {
              or_no = splitedOr[0]
            }
            payments_array.push(
              [
                {text: item.or_date, alignment: 'center'},
                {text: or_no, alignment: 'center'},
                {text: item.member_name, alignment: 'left'},
                {text: item.amount, alignment: 'center'},
                {text: item.remarks, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Net:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_net,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Dep. Amount:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: dep_amount,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Balance:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: total_diff,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'REMITTANCE SLIP', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.data.branch.branch_code, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'Collector: ' + collector, style: 'main_info'},
            {text: 'Dep. ID: ' + dep_id, style: 'main_info', color: 'red'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [85, 40, 160, 80, 120],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cs_line'},
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Collection Supervisor', style: 'cs_placeholder'},
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            // columns: [
            //   {
            //     text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //     alignment: 'center',
            //     style: 'tableExample'
            //   }
            // ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
